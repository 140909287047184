.place-number{
    width: 130px;
}

.place-number input{
    text-align: center;
}

.place-number input, .place-number span{
    border: solid 1px var(--theme-svg-bg-color);
    text-align: center;
    line-height: 1rem;
}

.place-number input:focus{
    border: solid 1px var(--theme-svg-bg-color);

}