#footer {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: var(--theme-footer-bg-color);
  box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.15);
}

#footer svg {
}

#footer a {
  color: var(--theme-text-color);
  text-decoration: none;
  font-size: 12px;
  display: block;
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  transition: background-color 0.5s;
}

#footer a.active {
  background-color: var(--theme-background-color);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  transition: background-color 0.5s;
}

#footer .icon {
  height: 23px;
  display: block;
  margin: auto;
}

.basket-count-value {
  color: var(--theme-svg-color);
  font-size: 0.9rem;
  vertical-align: middle;
}
