#categories {
  position: sticky;
  z-index: 1;
  top: 0px;
  padding-top: 10px;
  background-color: var(--theme-background-color);
}

.nav-scroller__items {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 10px;
  scroll-behavior: smooth;
}

.nav-scroller__item {
  display: flex;
  text-decoration: none;
}

.nav-scroller__item:not(:last-child) {
  margin-right: 6px;
}

.nav-scroller__item.active {
  background-color: var(--theme-btn-active-color);
  color: var(--theme-btn-active-text-color);
}

.card {
  background-color: var(--theme-card-bg-color);
  border: 1px solid var(--theme-card-border-color);
  color: var(--theme-text-color);
}

.card-img-basic {
  margin: 10px 10px 0px 10px;
  border-radius: 5px;
  border: 1px solid var(--theme-background-color);
}

.card-body {
  padding: 10px;
}

.card .price {
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  color: var(--theme-btn-active-text-color);
  border: 1px solid var(--theme-btn-active-color);
  border-radius: 5px;
  background-color: var(--theme-btn-active-color);
  text-align: center;
  line-height: 24px;
  padding-left: 5px;
  padding-right: 5px;
}

.card .price .value {
  text-align: center;
  display: inline-block;
}

.card .discount {
  float: right;
  color: var(--theme-background-color);
  background-color: var(--theme-svg-bg-color);
  border-radius: 5px;
  padding: 0px 5px;
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 0.9rem;
}

.card .price .prefix {
  float: left;
}

.card .price .postfix {
  float: right;
  font-size: 1.5rem;
}

.card .product-name {
  line-height: 1rem;
  font-size: 0.9rem;
  height: 4rem;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.card .out-of-stock {
  margin-top: 10px;
  text-align: center;
  line-height: 26px;
}

.modal-product .carousel-item img {
  border: 1px solid var(--theme-svg-bg-color);
}

.modal-product .product-description {
  background-color: var(--theme-card-border-color);
  text-align: center;
  margin: 0px -15px;
  padding: 10px 15px 10px 15px;
}

.modal-product .product-description .text {
  overflow: hidden;
  text-align: center;
  transition: 0.5s;
  max-height: 3rem;
}

.modal-product .product-description.open .text {
  height: unset;
  transition: 0.5s;
  max-height: var(--max-description-height);
}

.modal-product .product-description .btn {
  height: 10px;
  line-height: 10px;
  display: block;
  margin: 0 auto;
  position: relative;
  width: 100%;
  box-shadow: 0px -11px 7px var(--theme-card-border-color);
}

.modal-product .product-description.open .btn {
  rotate: 180deg;
}

.modal-product .button-group-sku {
  border: 1px solid var(--theme-btn-active-border-color);
  border-radius: 15px;
  overflow: hidden;
  background-color: var(--theme-btn-bg-color);
  width: 100%;
  text-align: left;
}

.modal-product .button-group-sku .btn {
  --bs-btn-color: var(--theme-btn-text-color);
  --bs-btn-hover-color: var(--theme-btn-text-color);
  --bs-btn-active-color: var(--theme-btn-active-text-color);

  --bs-btn-bg: var(--theme-btn-bg-color);
  --bs-btn-hover-bg: var(--theme-btn-bg-color);
  --bs-btn-active-bg: var(--theme-btn-active-color);

  --bs-btn-border-color: none;
  --bs-btn-hover-border-color: var(--theme-btn-border-color);
  --bs-btn-active-border-color: var(--theme-btn-border-color);

  border-radius: 15px !important;
}

.modal-product .button-group-exclude {
  width: 100%;
}

.modal-product .button-group-exclude .btn {
  max-width: fit-content;
  line-height: 15px;
  border-radius: 14px !important;
  margin-right: 10px;
  --bs-btn-color: var(--theme-btn-text-color);
  --bs-btn-hover-color: var(--theme-btn-text-color);
  --bs-btn-active-color: var(--theme-btn-text-color);

  --bs-btn-bg: none;
  --bs-btn-hover-bg: none;
  --bs-btn-active-bg: none;

  --bs-btn-border-color: var(--theme-border-gray);
  --bs-btn-hover-border-color: none;
  --bs-btn-active-border-color: none;
}

/* 
.modal-product .button-group-exclude .exclude {
  text-decoration: line-through;
  color: #b3b0ae;
}
.modal-product .button-group-exclude svg {
  transition: 0.5s;
  transform: rotate(135deg);
}
.modal-product .button-group-exclude .exclude svg {
  transform: rotate(0deg);
} */

.modal-product .button-group-include {
  max-width: 500px;
}

.modal-product .button-group-include .btn {
  margin-top: 0.5rem;
}

.modal-product .button-group-include .name {
  float: left;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 100%;
  text-align: start;
}

.modal-product .button-group-include .price {
  float: right;
  border: 1px solid var(--theme-btn-border-color);
  min-width: 100px;
  border-radius: 15px;
}

.modal-product .button-group-include .include .price {
  float: right;
  background-color: var(--theme-btn-active-color);
  color: var(--theme-btn-active-text-color);
}

.modal-product .modal-footer {
  display: block;
  background-color: var(--theme-footer-bg-color);
  box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.15);
}

.modal-product .old-price {
  display: inline;
  color: gray;
  line-height: 30px;
  text-decoration: line-through;
  margin-right: 10px;
  font-size: 0.9rem;
}

/* .modal-product .modal-footer div {
  padding: 0;
  margin: 0;
} */

.modal-product .modal-footer .btn {
  height: 40px;
}

.modal-product .modal-footer .switcher .btn {
  width: 40px;
  font-size: 1.5rem;
}

.modal-product .modal-footer .btn-next {
  min-width: 200px;
  font-size: 1rem;
}
