.group-delivery .btn-delivery-select {
  --bs-btn-bg: var(--theme-card-bg-color);
  --bs-btn-color: var(--theme-btn-text-color);
  --bs-btn-border-color: var(--theme-svg-bg-color);

  --bs-btn-active-bg: var(--theme-card-bg-color);
  --bs-btn-active-color: var(--theme-btn-text-color);
  --bs-btn-active-border-color: var(--theme-svg-bg-color);

  --bs-btn-hover-bg: var(--theme-card-bg-color);
  --bs-btn-hover-border-color: var(--theme-svg-bg-color);
  --bs-btn-hover-color: var(--theme-btn-text-color);

  font-size: 0.8rem;
  padding: 0px 0px 0px 10px;
  line-height: 30px;
}

.group-delivery .btn-delivery-select::after {
  content: none;
}

.group-delivery .btn-delivery-select button {
  background-color: transparent;
  border-color: transparent;

  --bs-btn-bg: transparent;
  --bs-btn-color: var(--theme-btn-text-color);
  --bs-btn-border-color: transparent;

  --bs-btn-active-bg: transparent;
  --bs-btn-active-color: var(--theme-btn-text-color);
  --bs-btn-active-border-color: transparent;

  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-color: var(--theme-btn-text-color);
  --bs-btn-hover-border-color: transparent;
}

.group-delivery .dropdown-menu {
  border-radius: 15px;
  padding: 10px;
}

.group-delivery .dropdown-close {
  display: block;
  width: auto;
  height: auto;
  padding: 0px;
  margin: 0px;
  --bs-dropdown-link-active-bg: transparent;
}

.group-delivery .btn-delivery-select.note {
  --bs-btn-bg: var(--theme-svg-bg-color);
  --bs-btn-color: var(--theme-background-color);

  --bs-btn-active-bg: var(--theme-svg-bg-color);
  --bs-btn-active-color: var(--theme-background-color);

  --bs-btn-hover-bg: var(--theme-svg-bg-color);
  --bs-btn-hover-border-color: var(--theme-svg-bg-color);
  --bs-btn-hover-color: var(--theme-background-color);

  font-size: 1rem;
}

.modal-dialog #adr-delivery label {
  font-size: 0.9rem;
}

.store-info-icon {
  width: 20px;
  text-align: center;
  margin-right: 5px;
  display: inline-block;
}

.dropdown .dropdown-menu {
  border: 1px solid var(--theme-btn-border-color);
}
