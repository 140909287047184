.order {
  border-radius: 10px;
  border: solid 1px var(--theme-btn-active-border-color);
  background-color: var(--theme-card-bg-color);
  color: var(--theme-text-color);
}

.order-header {
  font-weight: bold;
}

.order-header svg {
  zoom: 1.1;
  margin-right: 2px;
  vertical-align: baseline;
}
.order .number {
  font-weight: bold;
}

.order-item {
  margin-top: 0.5rem;
  border-radius: 10px;
  background-color: var(--theme-card-bg-color);
  color: var(--theme-text-color);
  padding: 5px 0px 5px 0px;
}

.order-item .exclude,
.order-item .include {
  font-size: 0.8rem;
}

.order-item-hr {
  border-style: dashed;
  margin-top: 5px;
  margin-bottom: 5px;
}
