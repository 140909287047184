.switcher {
  display: inline-block;
  margin: 0px;
  padding: 0px;
}

.switcher .btn-minus,
.switcher .btn-plus {
  display: inline-block;
  padding: 0px;
  margin: 0px;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  font-size: 20px;
}

.switcher .btn-minus {
  border: 1px solid gray;
  color: gray;
}

.switcher .btn-plus {
  border: 1px solid var(--theme-btn-border-color);
  background-color: var(--theme-btn-active-color);
  color: var(--theme-btn-active-text-color);
}

.switcher .count {
  text-align: center;
  display: inline-block;
  width: 30px;
}
