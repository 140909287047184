.theme-default,
.theme-1 {
  --theme-border-gray: gray;
  --theme-background-color: #ffffff;
  --theme-text-color: #000000;

  --theme-btn-bg-color: #f1f1f1;

  --theme-btn-active-color: #6cae17;
  --theme-btn-active-border-color: #6cae17;
  --theme-btn-active-text-color: #ffffff;

  --theme-btn-border-color: #6cae17;

  --theme-card-bg-color: #f1f1f1;

  --theme-btn-text-color: var(--theme-text-color);

  --theme-card-border-color: #f1f1f1;

  --theme-footer-bg-color: #f1f1f1;

  --theme-svg-color: #5a2f0d;
  --theme-svg-bg-color: #e7a748;
}

.theme-2 {
  --theme-background-color: #0c0a08;
  --theme-text-color: #ffffff;

  --theme-btn-bg-color: #321b08;
  --theme-btn-active-color: #9e491a;
  --theme-btn-active-text-color: #ffffff;

  --theme-btn-border-color: #febc9c;

  --theme-card-bg-color: #febc9c;

  --theme-btn-text-color: var(--theme-text-color);

  --theme-card-bg-color: #b87e61;

  --theme-card-border-color: #febc9c;

  --theme-footer-bg-color: #321b08;

  --theme-svg-color: #febc9c;
  --theme-svg-bg-color: #9e491a;
}

.color-notice {
  color: var(--theme-svg-color);
}

.theme-background-color {
  color: var(--theme-background-color);
}

/* html {
  scroll-behavior: inherit;
} */

:root {
  scroll-behavior: inherit;
  min-height: calc(100vh + 1px);
}

body {
  background-color: var(--theme-background-color);
  color: var(--theme-color);
  font-family: "Inter";
  /* scroll-behavior: inherit; */
  .tooltip {
    --bs-tooltip-zindex: 500;
  }
}

h1 {
  color: var(--theme-text-color);
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  margin: 0px;
}

h2 {
  color: var(--theme-text-color);
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  margin: 0px;
  /* background-color: aqua; */
  text-align: left;
}

a {
  text-decoration: none;
  color: var(--theme-text-color);
}

#content {
  padding-bottom: 5rem;
}

.btn {
  border-radius: 2rem;
  line-height: 1rem;
}

.btn-empty {
  border: none;
  background-color: transparent;
  --bs-btn-color: var(--theme-btn-text-color);
  padding: 0px 5px;
  line-height: 30px;
}

.btn-status-accepted,
.btn-status-delivered,
.btn-status-finished,
.btn-status-ready {
  --bs-btn-color: #ffffff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-active-color: #ffffff;

  --bs-btn-bg: #6cae17;
  --bs-btn-hover-bg: #6cae17;
  --bs-btn-active-bg: #6cae17;
}

.btn-status-cancelled {
  --bs-btn-color: #ffffff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-active-color: #ffffff;

  --bs-btn-bg: #9e491a;
  --bs-btn-hover-bg: #9e491a;
  --bs-btn-active-bg: #9e491a;
}

.btn-basic {
  --bs-btn-bg: var(--theme-btn-bg-color);
  --bs-btn-hover-bg: var(--theme-btn-bg-color);
  --bs-btn-active-bg: var(--theme-btn-bg-color);

  --bs-btn-border-color: var(--theme-btn-border-color);
  --bs-btn-hover-border-color: var(--theme-btn-border-color);
  --bs-btn-active-border-color: var(--theme-btn-border-color);
}

.btn-active {
  --bs-btn-color: var(--theme-btn-active-text-color);
  --bs-btn-hover-color: var(--theme-btn-active-text-color);
  --bs-btn-active-color: var(--theme-btn-active-text-color);

  --bs-btn-bg: var(--theme-btn-active-color);
  --bs-btn-hover-bg: var(--theme-btn-active-color);
  --bs-btn-active-bg: var(--theme-btn-active-color);

  --bs-btn-border-color: var(--theme-btn-active-color);
  --bs-btn-hover-border-color: var(--theme-btn-active-color);
  --bs-btn-active-border-color: var(--theme-btn-active-color);
}

.btn-next {
  --bs-btn-color: var(--theme-text-color);
  --bs-btn-hover-color: var(--theme-text-color);
  --bs-btn-active-color: var(--theme-text-color);

  --bs-btn-bg: var(--theme-svg-bg-color);
  --bs-btn-hover-bg: var(--theme-svg-bg-color);
  --bs-btn-active-bg: var(--theme-svg-bg-color);

  --bs-btn-border-color: var(--theme-svg-bg-color);
  --bs-btn-hover-border-color: var(--theme-svg-bg-color);
  --bs-btn-active-border-color: var(--theme-svg-bg-color);
  font-weight: 600;
}

.alert.alert-basic {
  background-color: var(--theme-btn-active-color);
  color: var(--theme-btn-active-text-color);
  text-align: center;
  padding: 0.5rem;
  margin: 0px;
}

.form-floating input {
  border-radius: 15px;
}

.modal-content {
  color: var(--theme-text-color);
  background-color: var(--theme-background-color);
  border: solid 1px var(--theme-btn-border-color);
}

.modal-body {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 50vh;
}

.placeholder {
  -webkit-mask-image: linear-gradient(
    90deg,
    #000 20%,
    rgba(0, 0, 0, 0.25) 60%,
    #000 80%
  );

  mask-image: linear-gradient(
    90deg,
    #000 20%,
    rgba(0, 0, 0, 0.25) 60%,
    #000 80%
  );
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

:root .placeholder.btn {
  background-color: gray;
  border: solid 1px transparent;
  cursor: wait;
}

.strike {
  color: #b3b0ae !important;
  position: relative;
  /* width: min-content !important; */
  text-decoration: line-through;
}

.btn-group.toggle-basic {
  border: 1px solid var(--theme-btn-active-color);
  border-radius: 2rem !important;
}

.toggle-basic.btn-group .btn {
  --bs-btn-active-bg: var(--theme-btn-active-color);
  --bs-btn-active-color: var(--theme-btn-active-text-color);
  --bs-btn-color: var(--theme-btn-text-color);
  --bs-btn-active-border-color: var(--theme-btn-active-color);

  --bs-btn-bg: none;
  --bs-btn-border-color: var(--theme-background-color);

  border-radius: 2rem !important;
}

.toggle-basic.btn-group .btn.disabled {
  --bs-btn-disabled-bg: var(--theme-background-color);
  --bs-btn-disabled-border-color: var(--theme-background-color);
  --bs-btn-disabled-color: var(--theme-border-gray);
}

/* 
.strike:before {
  content: "";
  border-bottom: 1.5px solid #b3b0ae;
  position: absolute;
  width: 97%;
  height: 50%;
  transform: rotate(-2deg);
}

.strike::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../media/strike.svg");
  background-size: cover;
}
*/

.modal .modal-header svg {
  zoom: 1.3;
}
