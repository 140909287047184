#basket-header {
  text-align: center;
}

#basket-header .step {
  display: inline-block;
  text-align: center;
  min-width: 98px;
}

/* #basket-header .step:not(:last-child) {
  padding-right: 30px;
} */

#basket-header .text {
  color: var(--theme-text-color);
  padding-bottom: 10px;
}

#basket-header .circle {
  position: relative;
  border-radius: 100%;
  border: 1px solid gray;
  width: 35px;
  height: 35px;
  line-height: 33px;
  margin: auto;
  background-color: var(--theme-background-color);
  color: gray;
}

#basket-header .step:not(:last-child) .circle::after {
  position: absolute;
  content: "";
  top: 17px;
  width: 80px;
  height: 0;
  border-top: 1px solid gray;
  left: 34px;
}

#basket-header .step-2 .step:nth-child(1) .circle {
  border: 1px solid var(--theme-btn-active-color);
  color: var(--theme-text-color);
}

#basket-header .step-2 .step:nth-child(1) .circle::after {
  border-top: 1px solid var(--theme-btn-active-color);
}

#basket-header .step-3 .step .circle {
  border: 1px solid var(--theme-btn-active-color);
  color: var(--theme-text-color);
}

#basket-header .step-3 .step .circle::after {
  border-top: 1px solid var(--theme-btn-active-color);
}

#basket-header .step.active .circle {
  background-color: var(--theme-btn-active-color);
  border: 1px solid var(--theme-btn-active-color);
  color: var(--theme-btn-active-text-color);
}

.basket-width {
  max-width: 540px;
}

.basket .basket-item {
  background-color: var(--theme-card-border-color);
  border-radius: 15px;
  padding: 10px;
}

.basket .basket-item .accordion {
  /* margin-top: 10px; */
  /* border-top: 1px solid var(--theme-border-gray); */
  transition: 0.5s ease;
  overflow: hidden;
  /* max-height: 0px; */

  &.open {
    max-height: var(--max-height);
  }
}

.basket .basket-item .accordion-container {
  padding-top: 10px;
  white-space: nowrap;
}

.basket .basket-item .accordion-container .accordion-line {
  font-size: 0.9rem;
  line-height: 1rem;
}

.basket .basket-item .accordion-container .accordion-name {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 95px);
  vertical-align: top;
}

.basket .basket-item .accordion-container .accordion-value {
  /* display: inline-block; */
  width: 90px;
  text-align: right;
  vertical-align: top;
  float: right;
}

.basket .basket-item .edit {
  position: absolute;
  bottom: 0;
}

.basket .basket-item .sum {
  border-top: 1px solid var(--theme-border-gray);
  font-weight: bold;
  margin-top: 10px;
  padding-top: 10px;
  line-height: 30px;
}

.basket .basket-item .hr {
  border-top: 1px solid var(--theme-border-gray);
  margin-top: 5px;
  padding-top: 5px;
}

.basket .basket-item .sum .color-notice {
  font-size: 18px;
}

.basket .basket-item .item-price {
  float: right;
}

.basket .basket-item .detail {
  /* position: relative; */
  position: absolute;
  display: inline;
  /* top: -5px; */
  margin: -3px;
  transition: 0.5s;
}

.basket .basket-item .detail svg {
  transition: 0.5s;
  cursor: pointer;
  scale: 1.2;
}

.basket .basket-item .prefix-minus,
.basket .basket-item .prefix-plus {
  display: inline-block;
  width: 1rem;
  text-align: center;
}

/* .basket .basket-item .detail.open svg {
  rotate: 135deg;
} */
.icon-variant {
  display: inline-block;
  width: 30px;
  text-align: center;
  margin-right: 0.2rem;
}

.select-variant {
  border-radius: 1rem;
  overflow: hidden;
  background-color: var(--theme-card-bg-color);
  padding: 0.5rem;
}

.select-variant label {
  text-align: left;
  border-radius: 0px;
  line-height: 2.2rem;
}

.time-slot {
}

.time-slot-title {
  font-weight: bold;
  white-space: nowrap;
  padding-right: 0.5rem;
  display: inline-block;
}

.time-slot-block {
  border-radius: 2rem;
  line-height: 1.5rem;
  background-color: var(--theme-card-bg-color);
  display: inline-block;
}

.time-slot-day {
  border-radius: 2rem;
  line-height: 1.5rem;
  padding: 5px 10px 5px 10px;
  background-color: var(--theme-btn-active-color);
  color: var(--theme-background-color);
  position: relative;
  left: -1px;
  display: inline-block;
}

.time-slot-time {
  padding: 0px 10px 0px 5px;
  display: inline-block;
}

.delivery-slot {
  border-radius: 2rem;
  text-align: center;
  border: 1px solid var(--theme-btn-border-color);
  width: 100px;
  display: inline;
  margin-top: 0.5rem;
  outline: none;
  box-shadow: none;
  width: 100%;
  display: inline-block;
}

.delivery-slot:focus {
  box-shadow: none;
  border: 1px solid var(--theme-btn-border-color);
}

#footer .btn-next {
  width: 100%;
  line-height: 30px;
  font-size: 1rem;
  display: inline-block;
}

.mySwiper {
  height: 10rem;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mySwiper .swiper-slide-active {
}

.mySwiper .swiper-slide {
  /* background-color: white; */
  /*line-height: 3rem !important;*/
  font-size: 1.5rem;
  /* transition: 1s; */
  color: gray;
}

.mySwiper .swiper-slide-active {
  /* background-color: gray; */
  /* font-weight: bold; */
  color: var(--theme-svg-color);
  transition: transform 0.25s;
  transform: scale(1.25);
  webkit-transform: scale(1.25);
}

/* body {
  touch-action: none !important;
} */
